import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Typography,
  Box,
  Container,
  Section,
  Layout,
  Grid,
} from '../components';
import styled from 'styled-components';
import ServiceHeroSection from '../components/ServiceHeroSection';
import applicationDevelopmentBanner from '../images/services/applicationDevelopment/mobileApplicationDevelopmentBanner.svg';
import softwareArchitecture from '../images/services/applicationDevelopment/softwareArchitecture.svg';
import webApplicationDevelopment from '../images/services/applicationDevelopment/webApplicationDevelopment.svg';
import mobileApplicationDevelopment from '../images/services/applicationDevelopment/applicationDevelopmentBanner.svg';
import sqaImg from '../images/services/applicationDevelopment/sqa.svg';

const ServiceContentImage = styled.img`
  box-shadow: 25px 25px 0px 0px var(--secondary-color);
  margin: 25px 0;
  width: 100%;

  @media screen and (max-width: 768px) {
    box-shadow: 10px 10px 0px 0px var(--secondary-color);
  }
`;

const applicationDevelopment = () => {
  return (
    <Layout title="Application Development">
      <Helmet>
        <meta
          name="description"
          content="Discover our comprehensive application development services, delivering innovative solutions tailored to your needs. From robust software architecture and web development to engaging mobile apps and rigorous quality assurance, we ensure top-notch performance, user-centric design, and reliable security for your projects."
        />
      </Helmet>
      <Container mt={{ xs: 8, md: 12 }}>
        <ServiceHeroSection
          title="Application Development"
          imageSrc={applicationDevelopmentBanner}
          imgAlt={'Application Development'}
        />
        <Section>
          <Typography my={5} variant="paragraph1">
            Our end-to-end application development services leverage innovative
            technology to meet your application needs. Get the perfect blend of
            fully functional, smooth UI for maximum engagement and higher
            conversion.
          </Typography>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box>
              <ServiceContentImage src={softwareArchitecture} />
            </Box>
            <Box>
              <Typography mt={2} variant="heading5">
                Software Architecture
              </Typography>

              <Typography mt={2} variant="paragraph1">
                Deep cross-domain knowledge and a human-centered design (HCD)
                approach enable our architectures to develop future-proof
                solutions that align with your objectives.
              </Typography>
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box flip={true}>
              <Typography mt={2} variant="heading5">
                Web Application Development
              </Typography>

              <Typography mt={2} variant="paragraph1">
                Our managed and process-driven services help you develop,
                deploy, and maintain feature-rich, goal-oriented applications
                with unmatched quality.
              </Typography>
            </Box>
            <Box>
              <ServiceContentImage
                style={{ float: 'right' }}
                src={webApplicationDevelopment}
              />
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box>
              <ServiceContentImage src={mobileApplicationDevelopment} />
            </Box>
            <Box>
              <Typography mt={2} variant="heading5">
                Mobile Application Development
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We offer application excellence through attractive UI, advanced
                coding, and scalable back-end infrastructure that cater to your
                target audience and drive growth.
              </Typography>
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box flip={true}>
              <Typography mt={2} variant="heading5">
                SQA (Software Quality Assurance)
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We employ a multi-layered testing approach to ensure optimum
                quality, performance, and reliable security of your web and
                mobile apps.
              </Typography>
            </Box>
            <Box>
              <ServiceContentImage style={{ float: 'right' }} src={sqaImg} />
            </Box>
          </Grid>
        </Section>
      </Container>
    </Layout>
  );
};

export default applicationDevelopment;
